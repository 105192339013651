import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { failReasonStatus, formatDate, statusReview } from "src/utils/utils-returns";
import { useTheme } from "@mui/material/styles";

const ReviewStatusContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 0.5rem;
  padding: 16px;
  margin: 0.625rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ShopeeReviewStatus = ({ reviewStatus }) => {
    const theme = useTheme();
    const bgColor = theme.palette.mode === "dark" ? "#1B263B" : "#f9f9f9";
  
    return (
      <ReviewStatusContainer bgColor={bgColor}>
        <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
          Revisão - Shopee
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${statusReview(reviewStatus?.review_status)} por ${reviewStatus?.user_name}${
            reviewStatus?.review_date ? ` em ${formatDate(reviewStatus?.review_date)}` : ""
          }.`}
        </Typography>
      </ReviewStatusContainer>
    );
  };