import React from "react";
import {
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
  Grid,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Sort } from "src/sections/dashboard/chat/sort";
import FilterAltOff from "@mui/icons-material/FilterAltOff";

const FilterBar = ({ filters, handleSearchChange, onKeyUp, handleResetFilters }) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md>
        <TextField
          fullWidth
          label="Pesquisar devolução"
          value={filters.query || ""}
          onChange={(e) => handleSearchChange(e.target.value)} // Passa apenas o valor da query
          onKeyUp={onKeyUp}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md="auto">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Sort />
          <IconButton onClick={handleResetFilters}>
            <Tooltip title="Remover filtros">
              <FilterAltOff />
            </Tooltip>
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterBar;
