import React, { useEffect, useState } from "react";
import { api } from "src/services/api";
import { Box, Grid } from "@mui/material";
import StatisticsCard from "./statistics-card";
import { NonAdministratorCard } from "src/sections/dashboard/account/components/non-adminstrator-card.component";
import { useAuth } from "src/hooks/use-auth";

const DashboardPage = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState({
    questionsAnswered: 0,
    questionsUnanswered: 0,
    questionsRead: 0,
    questionsUnread: 0,
    questionsTotal: 0,
    messagesAnswered: 0,
    messagesUnanswered: 0,
    messagesRead: 0,
    messagesUnread: 0,
    messagesTotal: 0,
    claimsAnswered: 0,
    claimsUnanswered: 0,
    claimsRead: 0,
    claimsUnread: 0,
    claimsTotal: 0,
    chatsAnswered: 0,
    chatsUnanswered: 0,
    chatsRead: 0,
    chatsUnread: 0,
    chatsTotal: 0,
  });

  const getMetrics = async () => {
    try {
      const tenant_id = window.localStorage.getItem("tenant_id");
      const { data } = await api.get(`/v3/metrics/${tenant_id}`);
      
      const tempStats = {
        questionsAnswered: 0,
        questionsUnanswered: 0,
        questionsRead: 0,
        questionsUnread: 0,
        questionsTotal: 0,
        messagesAnswered: 0,
        messagesUnanswered: 0,
        messagesRead: 0,
        messagesUnread: 0,
        messagesTotal: 0,
        claimsAnswered: 0,
        claimsUnanswered: 0,
        claimsRead: 0,
        claimsUnread: 0,
        claimsTotal: 0,
        chatsAnswered: 0,
        chatsAnswered: 0,
        chatsUnanswered: 0,
        chatsRead: 0,
        chatsUnread: 0,
        chatsTotal: 0,
      };

      data.forEach(
        ({
          conversation_type,
          messages_answered,
          messages_unanswered,
          messages_read,
          messages_unread,
          total,
        }) => {
          tempStats[conversation_type + "Answered"] += parseInt(
            messages_answered,
            10
          );
          tempStats[conversation_type + "Unanswered"] += parseInt(
            messages_unanswered,
            10
          );
          tempStats[conversation_type + "Read"] += parseInt(messages_read, 10);
          tempStats[conversation_type + "Unread"] += parseInt(
            messages_unread,
            10
          );
          tempStats[conversation_type + "Total"] += parseInt(total, 10);
        }
      );
      console.log(tempStats);
      setStats(tempStats);
    } catch (error) {
      console.error("Error fetching metrics:", error);
      toast.error("Não foi possível buscar as métricas", {
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getMetrics();
  }, []);

  if (user.userGroupName !== "Administrador") {
    return (
      <NonAdministratorCard
        message="Somente administradores podem visualizar as métricas."
      />
    )
  }

  return (
    <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Box mb={1.5}>
            <StatisticsCard
              title="Perguntas"
              data={[
                { name: "Não Lidas", value: stats.questionsUnread },
                { name: "Não Respondidas", value: stats.questionsUnanswered },
                { name: "Respondidas", value: stats.questionsAnswered },
                { name: "Lidas", value: stats.questionsRead },
                { name: "Total", value: stats.questionsTotal },
              ]}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box mb={1.5}>
            <StatisticsCard
              title="Mensagens"
              data={[
                { name: "Não Lidas", value: stats.messagesUnread },
                { name: "Não Respondidas", value: stats.messagesUnanswered },
                { name: "Respondidas", value: stats.messagesAnswered },
                { name: "Lidas", value: stats.messagesRead },
                { name: "Total", value: stats.messagesTotal },
              ]}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box mb={1.5}>
            <StatisticsCard
              title="Reclamações"
              data={[
                { name: "Não Lidas", value: stats.claimsUnread },
                { name: "Não Respondidas", value: stats.claimsUnanswered },
                { name: "Respondidas", value: stats.claimsAnswered },
                { name: "Lidas", value: stats.claimsRead },
                { name: "Total", value: stats.claimsTotal },
              ]}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Box mb={1.5}>
            <StatisticsCard
              title="Chats"
              data={[
                { name: "Não Lidas", value: stats.chatsUnread },
                { name: "Não Respondidas", value: stats.chatsUnanswered },
                { name: "Respondidas", value: stats.chatsAnswered },
                { name: "Lidas", value: stats.chatsRead },
                { name: "Total", value: stats.chatsTotal },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardPage;
