import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { api } from "src/services/api";
import toast from "react-hot-toast";

const MercadoLivreRefound = ({ open, onClose, activeChat }) => {
  const [loading, setLoading] = useState(false);

  const handleRefound = async () => {
    try {
      const activeClaim = activeChat?.claims?.find(
        (claim) => claim.status !== "closed"
      );

      if (!activeClaim) {
        toast.error("Nenhuma reclamação ativa encontrada para reembolso");
        return;
      }

      const response = await api.post(
        `/v1/claims/refound/${activeChat?.id}`,
        {
          claimId: activeClaim.claim_id,
          expected_resolution: "refund",
        }
      );

      if (response?.status === 200) {
        onClose();
        toast.success("Reembolso total enviado!");
      }
    } catch (error) {
      toast.error("Erro ao enviar reembolso total", {
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h4" component="h2" textAlign="center" sx={{ mt: 1 }}>
          Você vai reembolsar R$ {activeChat?.order?.total_amount} para {activeChat?.customer?.customer_name}
        </Typography>

        <Grid container direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <Grid item>
            <KeyboardDoubleArrowRightIcon />
          </Grid>
          <Grid item xs>
            <Typography textAlign="left" variant="subtitle2">
              A reclamação será encerrada. <strong>O Mercado Livre entenderá que você não quer o produto de volta,</strong>
              por isso quem fez a compra pode ficar com ele.
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
          <Grid item>
            <KeyboardDoubleArrowRightIcon />
          </Grid>
          <Grid item xs>
            <Typography textAlign="left" variant="subtitle2">
              As mensagens desta venda vão ficar disponíveis, somente para leitura. Se a pessoa que fez a
              compra te escrever, você pode responder.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mt: 4 }}>
          <LoadingButton
            disabled={loading}
            loading={loading}
            onClick={handleRefound}
            variant="contained"
            sx={{ mr: 1 }}
          >
            Confirmar reembolso
          </LoadingButton>

          <LoadingButton disabled={loading} onClick={onClose}>
            Cancelar
          </LoadingButton>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MercadoLivreRefound;
