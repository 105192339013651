import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useBilling } from 'src/contexts/billing-context';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';

export const BillingGuard = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const { billingData } = useBilling();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (isAuthenticated) {
      if (billingData) {
        if (billingData.expired) {
          router.replace(paths.billing);
        }
      }
      setChecked(true);
    } else {
      const searchParams = new URLSearchParams({
        returnTo: window.location.href,
      }).toString();
      const href = window.location.origin + `?${searchParams}`;
      router.replace(href);
    }
  }, [isAuthenticated, router]);
  
  useEffect(() => {
      check();
    },
    []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
};

BillingGuard.propTypes = {
  children: PropTypes.node
};
