import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";

export const NonAdministratorCard = (props) => {
    const { title, subheader, message } = props;
    return (
        <Stack spacing={3} {...props}>
            <Card>
                <CardHeader
                    title={title}
                    subheader={subheader}
                />
                <CardContent sx={{ pt: 0, my: 4, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                    {message}
                </Typography>
                </CardContent>
            </Card>
        </Stack>
    );
}