import React from "react";
import {
  Box,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Detail } from "./components/detail-return.js";
import TimeLine from "./components/time-line.js";
import { RightDrawer } from "src/components/right-drawer/index.js";
import { ReviewStatus } from "./components/review-status-component.js";

const DrawerDetail = ({ open, onClose, details, shippingHistory, reviewStatus }) => {
  const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));

  const hasValidDetails = details && (
    details.title ||
    details.problem ||
    details.due_date ||
    details.description ||
    details.action_responsible
  );
  return (
    <RightDrawer open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "8px 16px",
        }}
      >
        <IconButton color="inherit" onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box sx={{ padding: "16px" }}>
        {hasValidDetails && (
          <>
            <Detail details={details} />
            <Divider />
          </>
        )}
        {shippingHistory && shippingHistory.length > 0 && (
          <>
            <TimeLine shippingHistory={shippingHistory} />
            <Divider />
          </>
        )}
        {reviewStatus?.review_status && reviewStatus?.review_status !== "pending" && (
          <>
            <ReviewStatus reviewStatus={reviewStatus} />
          </>
        )}
      </Box>
    </RightDrawer>
  );
};

export default DrawerDetail;
