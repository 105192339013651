import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Modal, Box, IconButton, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { api } from "src/services/api";
import ShopeePartialRefound from "src/pages/return/components/shopee/shopee-partial-refund";
import MercadoLivrePartialRefound from "./mercadolivre-partial-refund";
import toast from "react-hot-toast";
import { Error } from "src/components/error";

const PartialRefound = ({ open, close, setRefundData, activeChat }) => {
  const [loading, setLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState("0.00");
  const [selectedPercentage, setSelectedPercentage] = useState(
    setRefundData?.available_offers?.[0].percentage
  );
  const selectedOffer = setRefundData?.available_offers?.find(
    (offer) => offer?.percentage === selectedPercentage
  );
  
  const handleSendOfferRefound = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/v1/claims/send-partial-refound/${activeChat?.id}`, {
        claim_id: activeChat?.claims?.[0]?.claim_id,
        percentage: selectedPercentage,
      });
      if (response?.status === 201) {
        toast.success("Oferta de reembolso enviada!");
      }
      close(false);
      setSelectedPercentage(null);
    } catch (error) {
      toast.error("Erro ao enviar oferta de reembolso", { position: "bottom-right" });
    } finally {
      setLoading(false);
    }
  };
  
  const handleSendOfferPartialRefundShopee = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/v1/chats/sendOffer/${activeChat?.id}`, {
        claim_id: activeChat?.claim_id,
        proposed_solution: "REFUND",
        proposed_adjusted_refund_amount: parseFloat(refundAmount),
      });
      if (response?.status === 201) {
        toast.success("Oferta de reembolso Shopee enviada!");
      }
      close(false);
    } catch (error) {
      toast.error("Erro ao enviar oferta de reembolso Shopee", { position: "bottom-right" });
    } finally {
      setLoading(false);
      close(true);
    }
  };

  const handleSend = () => {
    if (activeChat?.platform_name === "Shopee") {
      handleSendOfferPartialRefundShopee();
    } else {
      handleSendOfferRefound();
    }
  };

  const handleClose = () => {
    close(false);
    setSelectedPercentage(null);
    setError(false);
  };

  const isShopeeReturn = activeChat?.platform_name === "Shopee";

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        {isShopeeReturn ? (
          <ShopeePartialRefound
            setRefundData={(data) => setRefundAmount(data.refundAmount)}
            returnData={activeChat}
          />
        ) : (
          <MercadoLivrePartialRefound
            setRefundData={setRefundData}
            selectedPercentage={selectedPercentage}
            setSelectedPercentage={setSelectedPercentage}
            selectedOffer={selectedOffer}
          />
        )}

        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <LoadingButton disabled={loading} loading={loading} onClick={handleClose}>
            Cancelar
          </LoadingButton>
          <LoadingButton
            disabled={loading}
            loading={loading}
            onClick={handleSend}
            variant="contained"
            sx={{ mr: 1 }}
          >
            {isShopeeReturn ? "Enviar" : "Oferecer reembolso"}
          </LoadingButton>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PartialRefound;
