import { styled } from "@mui/material/styles";
import { neutral } from "src/theme/colors";
import { styled as styledComponents } from "styled-components";

export const Row = styled("div")(
  ({ justifyContent, alignItems, gap, width }) => ({
    display: "flex",
    justifyContent: justifyContent || "space-between",
    alignItems: alignItems || "center",
    padding: 0,
    marging: 0,
    gap: gap || "0.5rem",
    maxWidth: width || "",
  })
);

export const ItemsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  backgroundColor: 'var(--info-light)',
  border: '1px solid var(--primary-main)',
  width: "100%",
  padding: "0.5rem",
  maxHeight: "60vh",
}));

export const Spacing = styled("div")(({ direction, size }) => ({
  width: direction === "horizontal" ? size : "100%",
  height: direction === "horizontal" ? "100%" : size,
}));


export const Container = styledComponents.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  width: 100%;
  font-size: 0.875rem;
  @media (max-width: 600px) {
    margin-left: 0;
    font-size: 0.75rem;
  }
`;

export const ProductCard = styledComponents.div`
  display: flex;
  column-gap: 8px;
  color: var(--primary-dark);
  width: 100%;
  border-radius: 8px;
  padding: 4px;
`;

export const ProductName = styledComponents.a`
  text-decoration: none;
  color: var(--primary-dark);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  flex-wrap: wrap;
  word-wrap: break-word;
  font-size: 14px;
`;

export const TitleWrapperLimitLines = styledComponents.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
`;

export const ProductImage = styled("img")(({ size }) => ({
  width: size,
  height: size,
  objectFit: 'contain',
  border: '1px solid var(--primary-main)',
  borderRadius: '8px'
}));