import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const ShopeePartialRefound = ({ setRefundData, returnData }) => {
  const [refundAmount, setRefundAmount] = useState("0.00");

  const handleRefundChange = (e) => {
    let value = e.target.value.replace(",", ".");
    const maxRefundable = parseFloat(returnData?.refund_amount) || 0;
    const newValue = parseFloat(value);

    if (value !== "" && !isNaN(newValue)) {
      setRefundAmount(value);
      setRefundData({ refundAmount: parseFloat(value).toFixed(2) });
    }
  };

  const handleFocus = () => {
    if (refundAmount === "0.00") {
      setRefundAmount("");
    }
  };

  const handleBlur = () => {
    const maxRefundable = parseFloat(returnData?.refund_amount) || 0;
    const newValue = parseFloat(refundAmount);

    if (refundAmount === "" || isNaN(newValue) || newValue > maxRefundable) {
      setRefundAmount(maxRefundable.toFixed(2));
      setRefundData({ refundAmount: maxRefundable.toFixed(2) });
    } else {
      setRefundAmount(newValue.toFixed(2));
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="h2" textAlign="center">
          Oferecer Reembolso Parcial
        </Typography>
      </Stack>

      <Typography variant="subtitle1" component="div" sx={{ mt: 3 }}>
        Solicitação do comprador:
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2">Solução</Typography>
            <Typography variant="body2">Valor do reembolso</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2">Devolução e reembolso</Typography>
            <Typography variant="body2">{`R$${returnData?.refund_amount}`}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Typography variant="subtitle1" component="div" sx={{ mt: 4 }}>
        Sua Proposta:
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2">Solução</Typography>
            <Stack spacing={1}>
              <Typography variant="body2">Valor do reembolso</Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ mt: 0.5, display: "flex", alignItems: "center" }}
              >
                Máximo reembolsável: R${returnData?.refund_amount}
                <Tooltip title="Este valor é o preço do produto pago pelo comprador (após o desconto) pelos itens devolvidos">
                  <IconButton size="small" sx={{ ml: 0.5 }}>
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Typography variant="body2">Somente Reembolso</Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={refundAmount}
              onChange={handleRefundChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ShopeePartialRefound;
