import { AuthGuard } from 'src/guards/auth-guard';
import { BillingGuard } from 'src/guards/billing-guard';

export const withAuthGuard = (Component) => (props) => (
  <AuthGuard>
    <BillingGuard>
      <Component {...props} />
    </BillingGuard>
  </AuthGuard>
);
