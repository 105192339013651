import PropTypes from "prop-types";
import { Box, Button, Card, Container, IconButton, Link, Stack, SvgIcon, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { useTheme } from "@mui/material/styles";
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { useCallback, useMemo } from "react";
import { useFilters } from "src/contexts/filters-context";
import { useMobileMessage } from "src/contexts/mobile-message-context";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import LogoutIcon from '@mui/icons-material/Logout';

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
}));

const useCssVars = (color) => {
    const theme = useTheme();
    return useMemo(() => {
      switch (color) {
        case "blend-in":
          if (theme.palette.mode === "dark") {
            return {
              "--nav-bg": theme.palette.background.default,
              "--nav-color": theme.palette.neutral[100],
              "--nav-divider-color": theme.palette.neutral[800],
              "--nav-border-color": theme.palette.neutral[700],
              "--nav-logo-border": theme.palette.neutral[700],
              "--nav-item-color": theme.palette.neutral[400],
              "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-color": theme.palette.text.primary,
              "--nav-item-disabled-color": theme.palette.neutral[600],
              "--nav-item-icon-color": theme.palette.neutral[500],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[700],
              "--nav-item-chevron-color": theme.palette.neutral[700],
              "--nav-scrollbar-color": theme.palette.neutral[400],
            };
          } else {
            return {
              "--nav-bg": theme.palette.background.default,
              "--nav-color": theme.palette.text.primary,
              "--nav-divider-color": theme.palette.divider,
              "--nav-border-color": theme.palette.neutral[100],
              "--nav-logo-border": theme.palette.neutral[100],
              "--nav-section-title-color": theme.palette.neutral[400],
              "--nav-item-color": theme.palette.text.secondary,
              "--nav-item-hover-bg": theme.palette.action.hover,
              "--nav-item-active-bg": theme.palette.action.selected,
              "--nav-item-active-color": theme.palette.text.primary,
              "--nav-item-disabled-color": theme.palette.neutral[400],
              "--nav-item-icon-color": theme.palette.neutral[400],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[400],
              "--nav-item-chevron-color": theme.palette.neutral[400],
              "--nav-scrollbar-color": theme.palette.neutral[900],
            };
          }
  
        case "discreet":
          if (theme.palette.mode === "dark") {
            return {
              "--nav-bg": theme.palette.neutral[900],
              "--nav-color": theme.palette.neutral[100],
              "--nav-divider-color": theme.palette.neutral[800],
              "--nav-border-color": theme.palette.neutral[700],
              "--nav-logo-border": theme.palette.neutral[700],
              "--nav-item-color": theme.palette.neutral[400],
              "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-color": theme.palette.text.primary,
              "--nav-item-disabled-color": theme.palette.neutral[600],
              "--nav-item-icon-color": theme.palette.neutral[500],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[700],
              "--nav-item-chevron-color": theme.palette.neutral[700],
              "--nav-scrollbar-color": theme.palette.neutral[400],
            };
          } else {
            return {
              "--nav-bg": theme.palette.neutral[50],
              "--nav-color": theme.palette.text.primary,
              "--nav-divider-color": theme.palette.neutral[200],
              "--nav-border-color": theme.palette.divider,
              "--nav-logo-border": theme.palette.neutral[200],
              "--nav-section-title-color": theme.palette.neutral[500],
              "--nav-item-color": theme.palette.neutral[500],
              "--nav-item-hover-bg": theme.palette.action.hover,
              "--nav-item-active-bg": theme.palette.action.selected,
              "--nav-item-active-color": theme.palette.text.primary,
              "--nav-item-disabled-color": theme.palette.neutral[400],
              "--nav-item-icon-color": theme.palette.neutral[400],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[400],
              "--nav-item-chevron-color": theme.palette.neutral[400],
              "--nav-scrollbar-color": theme.palette.neutral[900],
            };
          }
  
        case "evident":
          if (theme.palette.mode === "dark") {
            return {
              "--nav-bg": theme.palette.neutral[800],
              "--nav-color": theme.palette.common.white,
              "--nav-divider-color": theme.palette.neutral[700],
              "--nav-border-color": "transparent",
              "--nav-logo-border": theme.palette.neutral[700],
              "--nav-item-color": theme.palette.neutral[400],
              "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-color": theme.palette.common.white,
              "--nav-item-disabled-color": theme.palette.neutral[500],
              "--nav-item-icon-color": theme.palette.neutral[400],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[500],
              "--nav-item-chevron-color": theme.palette.neutral[600],
              "--nav-scrollbar-color": theme.palette.neutral[400],
            };
          } else {
            return {
              "--nav-bg": theme.palette.neutral[800],
              "--nav-color": theme.palette.common.white,
              "--nav-divider-color": theme.palette.neutral[700],
              "--nav-border-color": "transparent",
              "--nav-logo-border": theme.palette.neutral[700],
              "--nav-item-color": theme.palette.neutral[400],
              "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
              "--nav-item-active-color": theme.palette.common.white,
              "--nav-item-disabled-color": theme.palette.neutral[500],
              "--nav-item-icon-color": theme.palette.neutral[400],
              "--nav-item-icon-active-color": theme.palette.primary.main,
              "--nav-item-icon-disabled-color": theme.palette.neutral[500],
              "--nav-item-chevron-color": theme.palette.neutral[600],
              "--nav-scrollbar-color": theme.palette.neutral[400],
            };
          }
  
        default:
          return {};
      }
    }, [theme, color]);
};

export const Layout = withAuthGuard((props) => {
    const { children } = props;
    const desktopUp = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
    const cssVars = useCssVars("evident");
    const theme = useTheme();
    const auth = useAuth();
    const router = useRouter();

    const handleLogout = useCallback(async () => {
      try {
        await auth.signOut();
        router.push(paths.index);
      } catch (err) {
        console.error(err);
        toast.error("Something went wrong!");
      }
    }, [auth, router]);

    return (
      <>
        <Box
          component="header"
          sx={{
            ...cssVars,
            backgroundColor: "var(--nav-bg)",
            borderBottomColor: "var(--nav-border-color)",
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            color: "var(--nav-color)",
            left: 0,
            position: "sticky",
            top: 0,
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
              px: 3,
              py: 1,
              [theme.breakpoints.down("desktop")]: {
                px: 1,
              },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                p: "4px",
                width: 170,
                [theme.breakpoints.down("desktop")]: {
                  width: 90,
                  p: "0px",
                },
              }}
            >
              {!desktopUp ? (
                <img src="/assets/images/logo.png" alt="logo" style={{ width: "100%" }} />
              ) : (
                <Logo />
              )}
            </Box>
    
            <Box sx={{ flexGrow: 1 }} />
    
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip title="Sair">
                <IconButton onClick={handleLogout} size="small">
                  <SvgIcon fontSize="small">
                    <LogoutIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
        <Box>{children}</Box>
      </>
    );
    
});

Layout.propTypes = {
  children: PropTypes.node
};
