import styled from "styled-components";
import { useMediaQuery } from "@mui/material";

const ProductImage = styled.img`
  width: ${(props) => `${props.width}px`};
  border-radius: 10%;
  border: 1px solid var(--primary-main);
  object-fit: cover;
  position: absolute;
  top: ${(props) => `${props.spacement}px`};
  left: ${(props) => `${props.spacement}px`};
`;
export const MultipleProductImages = ({ orderItems, width }) => {
  const desktopDown = useMediaQuery((theme) => theme.breakpoints.down("desktop"));
  const firstThree = orderItems?.slice(0, 3);
  const coefficient = desktopDown ? 0.5 : 1;
  return (
    <>
      {firstThree?.map((item, index, array) => (
        <ProductImage
          alt={item?.title ? item.title : item.title}
          src={item?.announcements_image_url ? item.announcements_image_url : item?.thumbnail}
          spacement={index * 10 * coefficient}
          width={
            array?.length === 2
              ? width - 5 * coefficient
              : width - 15 * coefficient
          }
        />
      ))}
    </>
  );
};
