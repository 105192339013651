import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TaxiAlertOutlinedIcon from "@mui/icons-material/TaxiAlertOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import {
  CalendarMonthOutlined,
  Diversity3Outlined,
  WebStoriesOutlined,
} from "@mui/icons-material";

export const getStatus = (status) => {
  switch (status?.toLowerCase()) {
    case "pending": //meli
    case "logistics_not_started"://shopee
      return "Pendente";
    case "handling": //meli
    case "logistics_pending_arrange"://shopee
      return "Preparação";
    case "ready_to_ship":// meli
    case "logistics_request_created": //shopee
      return "Pronto para despachar";
    case "shipped": // meli
    case "logistics_pickup_done": //shopee
      return "Devolução enviada";
    case "closed": // meli
      return "Encerrado";
    case "not_delivered": // meli
    case "logistics_delivery_failed": //shopee
      return "Não entregue";
    case "delivered": //meli
    case "logistics_delivery_done": //shopee
      return "Entregue";
    case "cancelled": //meli
    case "logistics_request_canceled": // shopee
      return "Cancelado";
    case "failed": //meli
    case "logistics_delivery_failed":// shopee
      return "Devolução falha";
    case "expired":
      return "Devolução expirada";
    case "not_recived":
      return "Não recebido";
    case "parcial_recived":
      return "Recebimento Parcial";
    case "received_damaged":
      return "Recebimento com avaria";
    case "completed":
      return "Concluída";
    case "received_divergence":
      return "Recebimento com divergência";
    case "review_by_marketplace":
      return "Revisado pelo marketplace";
    case "requested":
      return "Solicitado";
    case "accepted":
      return "Aceito";
    case "judging":
      return "Julgando";
    case "processing":
      return "Processando";
    case "seller_dispute":
      return "Disputa do vendedor";
    case "under_review_by_shopee":
      return "Em analisé pela Shopee";
    case "await_review_by_shopee":
      return "Aguardando revisão pela Shopee";    
    case "refunded":
      return "Reembolso pago"
    
    default:
      return status;
  }
};

export const getLabels = (reason) => {
  switch (reason.detail) {
    case "O produto chegou avariado":
      return {
        title: "Qual avaria você vê no produto?",
        description:
          "Informe se há marcas de uso, manchas, riscos ou outras avarias.",
      };
    case "A devolução está incompleta":
      return {
        title: "O que falta na devolução?",
        description:
          "Informe se chegaram menos produtos ou se faltam peças, acessórios ou outros itens.",
      };
    case "O produto devolvido é diferente do que eu enviei":
      return {
        title: "O que foi devolvido?",
        description:
          "Informe a diferença entre o produto recebido e o enviado.",
      };
    case "O produto não está no pacote":
      return {
        title: "O que veio no pacote?",
        description:
          "Informe se o pacote chegou vazio ou se contém outro produto.",
      };
    case "Reportar outro defeito no produto":
      return {
        title: "Qual é o problema com o produto?",
        description: "Informe por que o produto não chegou como esperado.",
      };
    case "Ainda não chegou":
      return {
        title: "Pode nos contar um pouco mais?",
        description:
          "Caso você ainda não tenha recebido o pacote, adicione as informações que possam ser úteis para podermos te ajudar.",
      };
    default:
      return {
        title: "Descreva o problema",
        description: "Informe os detalhes do problema.",
      };
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "dd MMM HH':'mm", { locale: ptBR });
};

export const formatCurrency = (value) => {
  return parseFloat(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const cardsDataExemple = [
  {
    title: "Total do mês atual",
    borderColor: "#758694",
    filterKey: "allMonth",
    icon: (
      <CalendarMonthOutlined
        sx={{
          width: 35,
          height: 35,
          color: "#758694",
        }}
      />
    ),
  },
  {
    title: "Pronto para despachar",
    borderColor: "#758694",
    filterKey: "ready_to_ship",
    icon: (
      <WebStoriesOutlined
        sx={{
          width: 35,
          height: 35,
          color: "#758694",
        }}
      />
    ),
  },
  {
    title: "Devolução enviada",
    borderColor: "#FFD93D",
    filterKey: "shipped",
    icon: (
      <LocalShippingOutlinedIcon
        sx={{
          width: 35,
          height: 35,
          color: "#FFD93D",
        }}
      />
    ),
  },
  {
    title: "Pendentes de revisão / ação",
    borderColor: "#31B7BC",
    filterKey: "to_review",
    icon: (
      <RuleIcon
        sx={{
          width: 35,
          height: 35,
          color: "#31B7BC",
        }}
      />
    ),
  },
  {
    title: "Concluídas com reembolso",
    borderColor: "#AFCB27",
    filterKey: "reviews",
    icon: (
      <CheckCircleOutlineIcon
        sx={{
          width: 35,
          height: 35,
          color: "#AFCB27",
        }}
      />
    ),
  },
  {
    title: "Não entregue",
    borderColor: "#FF0000",
    filterKey: "not_delivered",
    icon: (
      <TaxiAlertOutlinedIcon
        sx={{
          width: 35,
          height: 35,
          color: "#FF0000",
        }}
      />
    ),
  },
  {
    title: "Em mediação",
    borderColor: "#FFB347",
    filterKey: "inMediation",
    icon: (
      <Diversity3Outlined
        sx={{
          width: 35,
          height: 35,
          color: "#FFB347",
        }}
      />
    ),
  },
  {
    title: "Envio cancelado",
    borderColor: "#9d4edd",
    filterKey: "cancelled",
    icon: (
      <RemoveRoadIcon
        sx={{
          width: 35,
          height: 35,
          color: "#9d4edd",
        }}
      />
    ),
  },
];

export const failReasonStatus = (status) => {
  switch (status) {
    case "SRF2":
      return "O produto chegou avariado";
    case "SRF3":
      return "A devolução está incompleta";
    case "SRF4":
      return "O produto devolvido é diferente do que eu enviei";
    case "SRF5":
      return "O produto não está no pacote";
    case "SRF6":
      return "Reportar outro defeito no produto";
    case "SRF7":
      return "Ainda não chegou";
    default:
      return status;
  }
};

export const statusReview = (status) => {
  switch (status) {
    case "success":
      return "Revisado com sucesso";
    case "claimed":
      return "Revisão de devolução enviada pelo marketplace";
    case "failed":
      return "Falha na revisão de devolução";
    case "saleable":
      return "Produto retornado para venda";
    case "unsaleable":
      return "Produto sem condições para venda";
    case "discard":
      return "Produto descartado (produto diferente do enviado)";
    case "refund_paid":
      return "Reembolso pago"
    
    default:
      return "Ainda não revisado";
  }
};

export function getDisputeReason(codigos) {
  const razoes = {
    1: "Gostaria de rejeitar a reivindicação de não recebimento",
    2: "Gostaria de rejeitar a solicitação de devolução",
    3: "Concordo com a solicitação de devolução, mas não recebi o(s) produto(s) que deveria(m)",
    4: "Não recebi a devolução",
    5: "Produto danificado/defeituoso",
    6: "Produto faltando peças/não corresponde",
    7: "Produto fora do período de apreciação",
    8: "Outros",
    9: "Enviei o(s) item(ns) e tenho comprovante de envio",
    10: "Enviei o(s) item(ns) correto(s) conforme o pedido do comprador",
    11: "Enviei o(s) item(ns) em bom estado de funcionamento",
    12: "Concordei com a solicitação de devolução, mas não recebi o(s) item(ns) que deveria(m) ser devolvido(s)",
    13: "Concordei com a solicitação de devolução, mas recebi o(s) item(ns) errado(s)/danificado(s) do comprador",
    41: "Enviei o(s) item(ns) e tenho comprovante de envio",
    42: "Enviei o(s) item(ns) correto(s) conforme o pedido do comprador",
    43: "Enviei o(s) item(ns) em bom estado de funcionamento",
    44: "Incapaz de chegar a um acordo com o comprador",
    45: "Produtos fora do período de apreciação",
    46: "Não recebi o produto devolvido",
    47: "Recebi produtos devolvidos com danos físicos",
    48: "Recebi produtos devolvidos incompletos (quantidade/acessórios faltando)",
    49: "Recebi o produto devolvido errado",
    50: "Recebi o(s) item(ns) devolvido(s), a reivindicação do comprador está incorreta",
    51: "Incapaz de chegar a um acordo com o vendedor",
    53: "A reivindicação do comprador está incorreta",
    54: "O comprador foi reembolsado com um valor incorreto",
    55: "A reivindicação do comprador está correta, mas tenho outras preocupações",
    56: "Recebi o(s) item(ns) devolvido(s), mas o item está usado",
    81: "Não recebi o produto devolvido",
    82: "Recebi produtos devolvidos com danos físicos",
    83: "Recebi produtos devolvidos incompletos (quantidade/acessórios faltando)",
    84: "Recebi o produto devolvido errado",
    85: "Produtos fora do período de apreciação",
    86: "Recebi o(s) item(ns) devolvido(s), a reivindicação do comprador está incorreta",
    87: "O(s) produto(s) devolvido(s) estão excluídos do direito de desistência estatutário do comprador",
    88: "Comprador não respondeu no processo de devolução organizado pelo vendedor",
    89: "Recebi o(s) item(ns) devolvido(s), mas o item está usado"
  };

  return codigos.map(codigo => razoes[codigo] || "Razão desconhecida");
}


