import React from "react";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Scrollbar } from "src/components/scrollbar";
import { OrderItem } from "src/sections/dashboard/chat/order-drawer/order-item";
import { ItemsWrapper } from "src/sections/dashboard/chat/order-drawer/style";

const MultiplesItemsComponent = ({ open, onClose, items, loading }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 500, padding: 3, overflow: 'auto' },
      }}
    >
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            Detalhes da devolução
          </Typography>
          <Tooltip title="Fechar">
            <IconButton onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        <ItemsWrapper>
           <Scrollbar
             sx={{
               height: "100%",
               "& .simplebar-content": {
                 height: "100%",
               },
             }}
           >
            {items?.map((item, idx) => (
              <OrderItem
                key={idx}
                value={item}
                loading={loading}
                isLast={idx === items.length - 1}
              />
          ))}
         </Scrollbar>
        </ItemsWrapper>
      </Box>
    </Drawer>
  );
};

export default MultiplesItemsComponent;
