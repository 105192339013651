import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { api } from "src/services/api";
import toast from "react-hot-toast";

const ShopeeRefund = ({ open, onClose, activeChat }) => {
  const [loading, setLoading] = useState(false);

  const handleShopeeRefound = async () => {
    try {
      setLoading(true);
      const proposed_adjusted_refund_amount = activeChat?.refund_amount;
      const response = await api.post(`/v1/chats/sendOffer/${activeChat?.id}`, {
        claim_id: activeChat?.claim_id,
        proposed_solution: "REFUND",
        proposed_adjusted_refund_amount,
      });

      if (response?.status === 200) {
        onClose();
        toast.success("Reembolso total enviado!");
      }
    } catch (error) {
      toast.error("Erro ao enviar reembolso Shopee", {
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
      onClose(true);
    }
  };

  const handleShopeeConfirmRefund = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        `/v1/chats/send-confirm-offer/${activeChat?.id}`
      );

      if (response?.status === 201) {
        toast.success("Reembolso enviado!");
      }
    } catch (error) {
      toast.error("Erro ao enviar reembolso", {
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
      onClose(true);
    }
  };

  const handleMessageToRefun =
    activeChat?.canOfferOnlyRefund || activeChat?.canOfferRefundOrDispute
      ? "Tem certeza de que deseja aceitar esta proposta? A Shopee processará o reembolso ao comprador."
      : "Tem certeza de que deseja confirmar o reembolso? Uma vez confirmado, o pagamento será devolvido ao comprador e você não receberá o produto de volta.";

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton
          aria-label="Fechar"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h4"
          component="h2"
          textAlign="center"
          sx={{ mt: 1 }}
        >
          Você vai reembolsar R$ {activeChat?.refund_amount} para{" "}
          {activeChat?.customer}
        </Typography>

        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <KeyboardDoubleArrowRightIcon />
          </Grid>
          <Grid item xs>
            <Typography textAlign="left" variant="subtitle2">
              {handleMessageToRefun}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 4 }}
        >
          <LoadingButton disabled={loading} onClick={onClose} aria-label="Cancelar">
            Cancelar
          </LoadingButton>
          <LoadingButton
            disabled={loading}
            loading={loading}
            onClick={
              activeChat?.canOfferOnlyRefund || activeChat?.canOfferRefundOrDispute
                ? handleShopeeConfirmRefund
                : handleShopeeRefound
            }
            variant="contained"
            sx={{ mr: 1 }}
            aria-label="Confirmar reembolso"
          >
            Confirmar reembolso
          </LoadingButton>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ShopeeRefund;
