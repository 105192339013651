import { createContext, useContext, useState, useEffect, useMemo } from "react";

const BillingContext = createContext();

export const BillingProvider = ({ children }) => {
    const [billingData, setBillingData] = useState(() => {
      const storedData = sessionStorage.getItem("billingData");
      return storedData ? JSON.parse(storedData) : {};
    });
  
    const updateBillingData = (data) => {
      setBillingData(data);
      sessionStorage.setItem("billingData", JSON.stringify(data));
    };
    
    return (
      <BillingContext.Provider value={{ billingData, setBillingData: updateBillingData }}>
        {children}
      </BillingContext.Provider>
    );
};

export const useBilling = () => {
  return useContext(BillingContext);
};