import styled from "styled-components";

export const DetailContainer = styled.div`
    display: "flex";
    flex-direction: "row";
    flex-grow: "1";
    padding: "1px 1px 0px 0px";
    width: "100%";
    flex-wrap: "wrap";
`;

export const DetailInfo = styled.p`
    margin: 0;
    display: "flex";
    flex-direction: "row";
    font-size: "12px";
    text-align: "left";
    flex-wrap: wrap;
    word-wrap: break-word;
`;

export const DetailInfoTitle = styled.span`
    font-size: "12px";
    text-align: "left";
    flex-wrap: wrap;
    word-wrap: break-word;    
    margin-right: 5px;
`;

export const DetailInfoValue = styled.span`
    font-size: "12px";
    text-align: "left";
    flex-wrap: wrap;
    word-wrap: break-word;
    font-weight: bold;
`;