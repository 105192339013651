import { createContext, useContext, useState, useMemo } from "react";

const NotificationCounterContext = createContext();

export const NotificationCounterProvider = ({ children }) => {
  const [notificationCounter, setNotificationCounter] = useState({
    questions: {
      all: 0,
      unread: 0,
      unanswered: 0,
      archived: 0,
      closed: 0
    },
    messages: {
      all: 0,
      unread: 0,
      unanswered: 0,
      nomessages: 0,
      archived: 0,
      closed: 0
    },
    claims: {
      all: 0,
      unread: 0,
      unanswered: 0,
      nomessages: 0,
      archived: 0,
      closed: 0
    },
    chats: {
      all: 0,
      unread: 0,
      unanswered: 0,
    },
  });

  const notificationsTotalCount = () => {
    return ( 
      +notificationCounter['questions'].unanswered +
      +notificationCounter['messages'].unread +
      +notificationCounter['claims'].unread +
      +notificationCounter['chats'].unread
    );
  };

  const max = 99;
  const displayNotificationsCount = () => {
    const notificationsTotalCount = ( 
      +notificationCounter['questions'].unanswered +
      +notificationCounter['messages'].unread +
      +notificationCounter['claims'].unread +
      +notificationCounter['chats'].unread
    );
    return notificationsTotalCount > 0
          ? notificationsTotalCount > max ? `(${max}+) ` : `(${notificationsTotalCount}) `
          : "";
  }

  const contextValue = useMemo(() => {
    return { notificationCounter, setNotificationCounter, notificationsTotalCount, displayNotificationsCount };
  }, [notificationCounter]);

  return (
    <NotificationCounterContext.Provider value={contextValue}>
      {children}
    </NotificationCounterContext.Provider>
  );
};

export const useNotificationCounter = () => {
  const context = useContext(NotificationCounterContext);

  return context;
};


