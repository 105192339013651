import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const ActionMenu = ({
  row,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
  handleShopeeAwaitReturnModal,
  handleShopeePartialRefundModal,
  handleShopeeTotalRefundModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Ações">
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleOpenDescriptionModal(row);
            handleClose();
          }}
        >
          {row.description ? "Editar descrição" : "Adicionar descrição"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenStatusModal(row);
            handleClose();
          }}
        >
          Editar status Weello
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenInputFileModal(row);
            handleClose();
          }}
        >
          Inserir anexo
        </MenuItem>

        {row.canReview && (
          <MenuItem
            onClick={() => {
              handleOpenReviewModal(row);
              handleClose();
            }}
          >
            Revisar
          </MenuItem>
        )}

        
        {row.platform_name === "Shopee" && row?.reason.toLowerCase() !== "not_receipt" && (
          <>
            {row.canSendReturnOrRefundOffer && (
              <>
                <MenuItem
                  onClick={() => {
                    handleShopeeAwaitReturnModal(row);
                    handleClose();
                  }}
                >
                  Aguardar devolução
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleShopeePartialRefundModal(row);
                    handleClose();
                  }}
                >
                  Oferecer Reembolso parcial
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleShopeeTotalRefundModal(row);
                    handleClose();
                  }}
                >
                  Oferecer Reembolso total
                </MenuItem>
              </>
            )}
            
            {row.canOfferOnlyRefund && (
               <MenuItem
               onClick={() => {
                 handleShopeeTotalRefundModal(row);
                 handleClose();
               }}
             >
               Oferecer Reembolso total
             </MenuItem>
            )}

            {row.canSendDisputeOrAccept && (
            <>
              <MenuItem
                onClick={() => {
                  handleOpenReviewModal(row);
                  handleClose();
                }}
                >
                Abrir disputa
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleShopeeTotalRefundModal(row);
                  handleClose();
                }}
              >
                Aceitar
              </MenuItem>
             </>
            )}     

            {row.canOfferRefundOrDispute && (
            <>
              <MenuItem
                onClick={() => {
                  handleOpenReviewModal(row);
                  handleClose();
                }}
                >
                Abrir disputa
              </MenuItem>
              
              <MenuItem
                onClick={() => {
                  handleShopeeTotalRefundModal(row);
                  handleClose();
                }}
                >
                Oferecer Reembolso total
              </MenuItem>
            </>
            )}       
          </>
        )}
      </Menu>
    </>
  );
};

export default ActionMenu;
