import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close'; 

export const TopNotificationAlert = ({ notificationMessage }) => {
  const theme = useTheme();
  const [styles, setStyles] = useState({
    backgroundColor: theme.palette.communicate.default,    
    label: 'Comunicado'
  });


  const [visible, setVisible] = useState(true);

  const getStylesByType = (type) => {
    switch (type) {
      case 'Info':
        return { backgroundColor: theme.palette.communicate.info,  label: 'Informação' }; 
      case 'Warning':
        return { backgroundColor: theme.palette.communicate.warning,  label: 'Atenção' };
      case 'Critical':
        return { backgroundColor: theme.palette.communicate.critical,  label: 'Crítico' }; 
      default:
        return { backgroundColor: theme.palette.communicate.default,  label: 'Comunicado' }; 
    }
  };

  useEffect(() => { 
    const styles = getStylesByType(notificationMessage.type); 
    setStyles(styles);
  }, [notificationMessage.type]);

  const handleClose = () => {
    setVisible(false);
  };
  
  if (!visible) return null;

  return (
    <Box 
      style={{ backgroundColor: styles.backgroundColor }} 
    >
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Box className="flex-1 h-[64px] flex items-center" sx={{ paddingLeft: '2rem' }}>         
          <span className="flex-1" style={{ color: 'white' }}>
            {notificationMessage.message}           
            {notificationMessage.redirect_link && notificationMessage.redirect_link !== '' && (
              <>
                : <a href={notificationMessage.redirect_link} style={{ color: theme.palette.communicate.linkText, marginLeft: '0.5rem' }} target="_blank" rel="noopener noreferrer">Clique aqui para saber mais detalhes...</a>
              </>
            )}
          </span>
        </Box>
        <IconButton edge="end" color="inherit" onClick={handleClose} sx={{ marginRight: '1rem' }}>
          <CloseIcon className="shrink-0" />
        </IconButton>
      </Stack>
    </Box>
  );
};
