import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { api } from "src/services/api";

const AccountsContext = createContext();

export const AccountsProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([]);
  const contextValue = useMemo(() => {
    return {
      accounts,
      setAccounts,
      reset: () => setAccounts([]),
    };
  }, [accounts]);
  return (
    <AccountsContext.Provider value={contextValue}>
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccounts = () => {
  const context = useContext(AccountsContext);

  const fetchAccounts = useCallback(async () => {
    try {
      const marketplacesAccounts = await api.get(`/v1/account/marketplaces?`);
      context.setAccounts(
        marketplacesAccounts.data.rows.filter(
          ({ platform }) => platform.acronym === "MLB" || platform.acronym === "SHO" || platform.acronym === "MZL")
        );
    } catch (error) {
      throw new Error(error.message);
    }
  }, [context.accounts]);

  return { ...context, fetchAccounts };
};
