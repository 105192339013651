import { MercadoLivreReviewStatus } from "./mercadolivre-review-status";
import { ShopeeReviewStatus } from "./shopee/shopee-review-status";

export const ReviewStatus = ({ reviewStatus }) => {
  if (reviewStatus.platform_name?.toLowerCase() === "shopee") {
    return <ShopeeReviewStatus reviewStatus={reviewStatus} />;
  } else if (reviewStatus.platform_name?.toLowerCase() === "mercadolivre") {
    return <MercadoLivreReviewStatus reviewStatus={reviewStatus} />;
  } else {
    return null;
  }
};