import React from "react";
import { Grid, Typography, TextField, FormControl, Select, MenuItem, InputAdornment } from "@mui/material";

const MercadoLivrePartialRefound = ({ setRefundData, selectedPercentage, setSelectedPercentage, selectedOffer }) => {
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h4"
        component="h2"
        textAlign="center"
        sx={{ mt: 1 }}
      >
        Porcentagem de Reembolso
      </Typography>
      <Typography
        id="modal-modal-description"
        variant="h6"
        component="h2"
        textAlign="center"
        sx={{ mt: 4 }}
      >
        Depois que o comprador aceitar a oferta, esta reclamação será encerrada e deixará de afetar sua reputação.
      </Typography>
      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start" marginTop={2}>
        <Grid item xs={12} sm={4}>
          <Typography textAlign="center" variant="subtitle2">
            Preço do produto
          </Typography>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={setRefundData?.available_offers[0]?.amount.toFixed(2)}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            disabled
            sx={{ mt: 1 }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography textAlign="center" variant="subtitle2">
            Porcentagem de reembolso
          </Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Select
              labelId="percentage-refound-select-label"
              id="percentage-refound-select"
              value={selectedPercentage}
              onChange={(e) => setSelectedPercentage(e.target.value)}
              size="small"
              displayEmpty
              renderValue={(value) => value || "Selecione"}
            >
              {setRefundData?.available_offers.map((offer) => (
                <MenuItem key={offer.percentage} value={offer.percentage}>
                  {offer.percentage}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography textAlign="center" variant="subtitle2">
            Reembolso para o comprador
          </Typography>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={selectedOffer?.amount.toFixed(2) || ""}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            disabled
            sx={{ mt: 1 }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MercadoLivrePartialRefound;
