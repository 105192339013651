import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Skeleton,
  Grid,
  useMediaQuery,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { useSelector, useDispatch } from "react-redux";
import { findAllReturns } from "src/slices/returns";
import { useSearchParams } from "react-router-dom";
import { useFilters } from "src/contexts/filters-context";
import { useUser } from "src/contexts/user-context";
import FilterBar from "./components/filter-bar-component.js";
import ReturnRow from "./components/table-row-component.js";
import MobileReturnCard from "./components/mobile-return.js";
import { Scrollbar } from "src/components/scrollbar";
import UploadFileModal from "./input-file-modal";
import ReturnAttachmentList from "./attachment-list-modal";
import ReviewModal from "./review-modal";
import DrawerDetail from "./drawer-detail";
import { ListObjectsV2Command } from "@aws-sdk/client-s3";
import { api } from "src/services/api";
import toast from "react-hot-toast";
import StatusModal from "./components/status-weello-component.js";
import DescriptionModal from "./components/description-component.js";
import { Sort } from "src/sections/dashboard/chat/sort";
import { s3 } from "src/utils/storage-provider.js";
import { FilterAltOff } from "@mui/icons-material";
import CounterCard from "./components/card-counter-component.js";
import { cardsDataExemple } from "src/utils/utils-returns.js";
import CounterCardMobile from "./components/card-counter-mobile-component.js";
import PartialRefound from "src/sections/dashboard/chat/partial-refound.js";
import MultiplesItemsComponent from "./components/multiples-items-component.js";
import AwaitReturnModal from "./components/shopee/await-return.js";
import Refound from "src/sections/dashboard/chat/refound.js";
import { CircularProgress } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";


const ReturnTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const claimId = searchParams.get("claimId");
  const { returns, paginate, loading } = useSelector((state) => state.returns);
  const dispatch = useDispatch();
  const { filters, setFilters, reset } = useFilters();
  const { user } = useUser();
  const desktopDown = useMediaQuery((theme) =>
    theme.breakpoints.down("desktop")
  );
  const [expandedRow, setExpandedRow] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openInputFileModal, setOpenInputFileModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openAttachmentListModal, setOpenAttachmentListModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsExist, setAttachmentsExist] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedReturnId, setSelectedReturnId] = useState(null);
  const [selectedReturnItem, setSelectedReturnItem] = useState(null);
  const [localReturns, setLocalReturns] = useState(returns);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [selectedDetailItem, setSelectedDetailItem] = useState(null);
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [selectedFilterKey, setSelectedFilterKey] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [cardFilter, setCardFilter] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);    
  const [updatedOnReview, setUpdatedOnReview] = useState(false);
  const [openShopeeAwaitReturnModal, setOpenShopeeAwaitReturnModal] = useState(false);
  const [openShopeePartialRefundModal, setOpenShopeePartialRefundModal] = useState(false);
  const [openShopeeTotalRefundModal, setOpenShopeeTotalRefundModal] = useState(false);
  const [openItemsModal, setOpenItemsModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [refundData, setRefundData] = useState({});
  const [mobilePage, setMobilePage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  
  const fetchCardData = async () => {
    try {      
      const response = await api.get("/v1/claims/cards");
      const data = response.data;
      
      const updatedCardsData = cardsDataExemple.map((card) => {
        const backendData = data.find((item) => item.statusMP === card.filterKey);
        return backendData
          ? { ...card, value: backendData.count }
          : { ...card, value: 0 };
      });
  
      setCardsData(updatedCardsData);
      setInitialLoad(false);
    } catch (error) {
      console.error("Erro ao buscar dados dos cards:", error);
      toast.error("Erro ao carregar dados dos cards");
    }
  };
  
  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  
  const handleCardClick = (filterKey) => {    
    reset();
    setCardFilter(filterKey);
    setSelectedFilterKey(filterKey);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter: filterKey,
      query: "",
    }));
  };


  const fetchReturnsData = async (mobilePage = 0) => {
    try {
      setIsLoading(true);
  
      const pageToFetch = parseInt(desktopDown ? mobilePage : filters.page);
      const sizeToFetch = parseInt(desktopDown ? 10 : filters.size);
  
      let activeFilter = filters.filter;
      if (cardFilter !== "" && !filters.filter.includes(cardFilter)) {
        activeFilter = `${cardFilter}+${filters.filter}`;      }
  
      const result = await dispatch(
        findAllReturns({
          page: pageToFetch,
          size: sizeToFetch,
          query: filters.query,
          filter: activeFilter,
          account: filters.account,
          sort: filters.order,
          claim_id: claimId,
        })
      ).unwrap();
      
      // if (Array.isArray(result.rows) && result.rows.length > 0) {
      //   setLocalReturns((prevReturns) =>
      //     desktopDown ? [...prevReturns, ...result.rows] : result.rows
      //   );
      //   setTotalCount(result.totalCount);
      //   setHasMoreData(result.rows.length === 10);
      // } else if(!desktopDown) {
      //   setLocalReturns(result.rows);
      //   setTotalCount(result.totalCount);
      //   setHasMoreData(false);
      // }else {
      //   setTotalCount(result.totalCount);
      //   setLocalReturns(result.rows);
      //   setIsLoading(false);
      //   setHasMoreData(false);       
      // }
  
      if (Array.isArray(result.rows)) {
        setLocalReturns((prevReturns) =>
          desktopDown && mobilePage > 0
            ? [...prevReturns, ...result.rows]
            : result.rows
        );
        setTotalCount(result.totalCount);
        setHasMoreData(result.rows.length === sizeToFetch);
      } else {
        setLocalReturns([]);
        setTotalCount(0);
        setHasMoreData(false);
      }

      if (result?.rows.length > 0) {
        const checkAttachments = async () => {
          const attachmentsStatus = {};
          for (const returnItem of result.rows) {
            const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnItem.id}/`;
            const params = {
              Bucket: process.env.REACT_APP_AWS_BUCKET,
              Prefix: attachmentsPath,
            };
            try {
              const data = await s3.send(new ListObjectsV2Command(params));
              attachmentsStatus[returnItem.id] =
                data.Contents && data.Contents.length > 0;
            } catch (error) {
              console.error("Erro ao verificar anexos:", error);
              attachmentsStatus[returnItem.id] = false;
            }
          }
          setAttachmentsExist(attachmentsStatus);
        };
        await checkAttachments();
      }
    } catch (error) {
      console.error("Erro ao buscar devoluções:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(!desktopDown){
      fetchCardData(); 
      fetchReturnsData();
    }   
  }, [
    user.customer,
    filters.filter,  
    filters.account,
    filters.order,
    paginate.page,
    paginate.size,   
    dispatch,
    cardFilter,
    updatedOnReview,
  ]); 

  function onChange(e) {
    setFilters((prev) => ({
      ...prev,
      query: e.target.value,
    }));
  
    if (e.target.value === "") {
      setMobilePage(0);
      setLocalReturns([]);
      fetchReturnsData(0);
    } else if (e.target.value.length >= 3) {
      if (desktopDown) {
        setMobilePage(0);
        setLocalReturns([]);
        debounced(e.target.value);
      }
    }
  } 

  const debounced = useDebouncedCallback((search) => {   
    if(desktopDown){
      fetchCardData(); 
      fetchReturnsData(0);
    }else {
      fetchCardData(); 
      fetchReturnsData();
    }    
  }, 1000);
  
  
  const onKeyUp = (e) => {
    if (e.key === "Enter" && filters.query.length >= 3) {
      debounced(e.target.value);
    }
  };
  
  const handleInputChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      query: value,
    })); 
    
    if (value === "") {
      fetchReturnsData();
    } else if (value.length >= 3) {
      if(!desktopDown)
        debounced(value);
    }
  }; 

  useEffect(() => {
    if(!desktopDown){
      if (filters.query === "")
        fetchReturnsData();
    }else {
      if (filters.query === "")
        fetchReturnsData(0);
    }
    
  }, [filters.query]);  
  
  const observer = useRef();
 
  useEffect(() => {
    if (!desktopDown || isLoading || !hasMoreData) return;
  
    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setMobilePage((prevPage) => prevPage + 1);
      }
    };
  
    observer.current = new IntersectionObserver(handleObserver);
    const scrollAnchor = document.getElementById('scroll-anchor');
    if (scrollAnchor) observer.current.observe(scrollAnchor);
  
    return () => observer.current.disconnect();
  }, [desktopDown, isLoading]);

  useEffect(() => {
    if (desktopDown) {
      fetchCardData(); 
      fetchReturnsData(mobilePage);
    }
  }, [mobilePage,
      desktopDown,
      user.customer,
      filters.filter,
      filters.account,
      filters.order,
      paginate.page,
      paginate.size,   
      dispatch,
      cardFilter,
      updatedOnReview,]);  

  const updateAttachmentStatus = async (returnId) => {
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnId}/`;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Prefix: attachmentsPath,
    };
    try {
      const data = await s3.send(new ListObjectsV2Command(params));
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnId]: data.Contents && data.Contents.length > 0,
      }));
    } catch (error) {
      console.error("Erro ao verificar anexos:", error);
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnId]: false,
      }));
    }
  };

  useEffect(() => {
    if (claimId) {
      setSearchParams({});
    }
  }, [filters, claimId, setSearchParams]);

  // const onChangeFilter = useCallback(
  //   (params) => {      
  //     dispatch(findAllReturns(params));
  //   },
  //   [dispatch]
  // );

  const onRowsPerPageChanged = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      size: parseInt(event.target.value),
      page: 0
    }));
    dispatch(
      findAllReturns({
        page: 0,
        size: parseInt(event.target.value),
      query: filters.query,
      filter: filters.filter,
      account: filters.account,
      sort: filters.sort,
    })
  );
};

  const onPageChange = (_, newPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      size: paginate.size,
      page: newPage
    }));
    dispatch(
      findAllReturns({
        page: newPage,
        size: paginate.size,
        query: filters.query,
        filter: filters.filter,
        account: filters.account,
        sort: filters.sort,
      })
    );
  };
 


  const handleResetFilters = () => {
    reset();
    setCardFilter("");
    setFilters((prevFilters) => ({
      ...prevFilters,
      query: "",
    }));
    setSelectedFilterKey("all");
    if (claimId) {
      setSearchParams({});
    }
  };

  const handleOpenStatusModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedStatus(returnItem.statusWeello);
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    try {
      setLoadingComponents(true);
      await api.put(
        `/v1/claims/update-statusWeello-description/${selectedReturnId}`,
        {
          statusWeello: selectedStatus,
        }
      );
      toast.success("Status Weello atualizado com sucesso");
      const updatedReturns = localReturns.map((item) =>
        item.id === selectedReturnId
          ? { ...item, statusWeello: selectedStatus }
          : item
      );
      setLocalReturns(updatedReturns);
      handleCloseStatusModal();
    } catch (error) {
      toast.error("Erro ao atualizar status Weello", {
        position: "bottom-right",
      });
    } finally {
      setLoadingComponents(false);
    }
  };

  const handleOpenDescriptionModal = async (returnItem) => {
    setSelectedReturnId(returnItem.id);
    try {
      const response = await api.get(
        `/v1/claims/get-description/${returnItem.id}`
      );
      setSelectedDescription(response.data.description);
    } catch (error) {
      toast.error("Erro ao carregar descrição", {
        position: "bottom-right",
      });
    }
    setOpenDescriptionModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setOpenDescriptionModal(false);
  };

  const handleDescriptionChange = (event) => {
    setSelectedDescription(event.target.value);
  };

  const handleUpdateDescription = async () => {
    try {
      setLoadingComponents(true);
      await api.put(
        `/v1/claims/update-statusWeello-description/${selectedReturnId}`,
        {
          description: selectedDescription,
        }
      );
      toast.success("Descrição atualizada com sucesso");
      const updatedReturns = localReturns.map((item) =>
        item.id === selectedReturnId
          ? { ...item, description: selectedDescription }
          : item
      );
      setLocalReturns(updatedReturns);
      handleCloseDescriptionModal();
    } catch (error) {
      toast.error("Erro ao atualizar descrição", {
        position: "bottom-right",
      });
    } finally {
      setLoadingComponents(false);
    }
  };

  const handleOpenInputFileModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenInputFileModal(true);
  };

  const handleCloseInputFileModal = () => {
    setOpenInputFileModal(false);
  };

  const handleOpenReviewModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenReviewModal(true);
  };
  
  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
  };
  
  const handleShopeeAwaitReturnModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenShopeeAwaitReturnModal(true);
  };

  const handleCloseShopeeAwaitReturnModal = () => {
    setOpenShopeeAwaitReturnModal(false);
  };

  const handleShopeePartialRefundModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenShopeePartialRefundModal(true);
  };

  const handleCloseShopeePartialRefundModal = () => {
    setOpenShopeePartialRefundModal(false);
  };

  const handleShopeeTotalRefundModal = (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    setOpenShopeeTotalRefundModal(true);
  };

  const handleCloseShopeeTotalRefundModal = () => {
    setOpenShopeeTotalRefundModal(false);
  };

  const handleOpenAttachmentListModal = async (returnItem) => {
    setSelectedReturnId(returnItem.id);
    setSelectedReturnItem(returnItem);
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${returnItem.id}/`;

    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Prefix: attachmentsPath,
      };

      const data = await s3.send(new ListObjectsV2Command(params));
      const s3Attachments = data.Contents
        ? data.Contents.map((item) => ({
            name: item.Key.split("/").pop(),
            url: `https://assets-s3.weesutech.com.br/${item.Key}`,
          }))
        : [];

      setAttachments(s3Attachments);
      setAttachmentsExist((prev) => ({
        ...prev,
        [returnItem.id]: s3Attachments.length > 0,
      }));
      setOpenAttachmentListModal(true);
    } catch (error) {
      console.error("Erro ao carregar anexos:", error);
      toast.error("Erro ao carregar anexos", {
        position: "bottom-right",
      });
    }
  };

  const handleCloseAttachmentListModal = () => {
    setOpenAttachmentListModal(false);
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);

    if (updatedAttachments.length === 0) {
      setAttachmentsExist((prev) => ({
        ...prev,
        [selectedReturnId]: false,
      }));
    }
  };

  const handleOpenDetailDrawer = (returnItem) => {
    setSelectedDetailItem(returnItem);
    setOpenDetailDrawer(true);
  };

  const handleCloseDetailDrawer = () => {
    setOpenDetailDrawer(false);
  };

  const handleOpenItemsDrawer = (items) => {
    setModalItems(items);
    setOpenItemsModal(true);
  };

  const handleCloseItemsDrawer = () => {
    setOpenItemsModal(false);
    setModalItems([]);
  };  

  const hasFulfillment = localReturns?.some(
    (item) => (item?.logistic_type === "fulfillment" || item?.logistic_type === "full"
    || item?.logistic_type === "flex" || item?.logistic_type === "turbo")

  );
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        mt: desktopDown ? 2 : 5,
      }}
    >
      {desktopDown ? (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "100vh",
            height: "21.25em",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ padding: 2, width: "100%" }}
            display="flex"
            justifyContent="space-around"
          >
            {cardsData.map((card, index) => (
              <Grid item xs={5} key={index}>
                <CounterCardMobile
                  title={card.title}
                  value={card.value}
                  borderColor={card.borderColor}
                  icon={card.icon}
                  onClick={() => handleCardClick(card.filterKey)}
                  isSelected={selectedFilterKey === card.filterKey}
                />
              </Grid>
            ))}
          </Grid>
          <Stack
            alignContent="center"
            alignSelf="center"
            spacing={1}
            sx={{ mr: 2, ml: 2 }}
          >
            <TextField
              width="60px"
              label="Pesquisar devolução"
              value={filters.query || ""}
              onChange={(e) => onChange(e)}
              onKeyUp={onKeyUp}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={1}
            sx={{ padding: 2 }}
            width="100%"
          >
            <Sort />
            <IconButton onClick={handleResetFilters}>
              <Tooltip title="Remover filtros">
                <FilterAltOff />
              </Tooltip>
            </IconButton>
          </Stack>
          <Box justifySelf="center" width="95%" sx={{ padding: 2 }}>
            {localReturns.map((item, index) => (
              <MobileReturnCard
                key={index}
                item={item}
                index={index}
                expandedRow={expandedRow}
                handleExpandClick={handleExpandClick}
                attachmentsExist={attachmentsExist}
                handleOpenAttachmentListModal={handleOpenAttachmentListModal}
                handleOpenDetailDrawer={handleOpenDetailDrawer}
                handleOpenDescriptionModal={handleOpenDescriptionModal}
                handleOpenStatusModal={handleOpenStatusModal}
                handleOpenInputFileModal={handleOpenInputFileModal}
                handleOpenReviewModal={handleOpenReviewModal}
                handleShopeeAwaitReturnModal={handleShopeeAwaitReturnModal}
                handleShopeePartialRefundModal={handleShopeePartialRefundModal}
                handleShopeeTotalRefundModal={handleShopeeTotalRefundModal}
                hasFulfillment={hasFulfillment}
                onOpenItemsDrawer={handleOpenItemsDrawer}
              />
            ))}
            <div id="scroll-anchor" />
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}      
          </Box>
        </Box>
      ) : (
        <Card sx={{ width: "90%", height: "auto" }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <CardContent>
              <Grid container spacing={4} sx={{ mt: 0.5, mb: 4 }}>
              {initialLoad
                ? Array.from({ length: 8 }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height="100px"
                          />
                        </Grid>
                      ))
                    : cardsData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <CounterCard
                            title={card.title}
                            value={card.value}
                            borderColor={card.borderColor}
                            icon={card.icon}
                            onClick={() => handleCardClick(card.filterKey, false)}
                            isSelected={selectedFilterKey === card.filterKey}
                          />
                        </Grid>
                      ))}
                </Grid>
                <FilterBar
                  filters={filters}
                  setFilters={setFilters}                  
                  onKeyUp={onKeyUp}
                  handleResetFilters={handleResetFilters}
                  handleSearchChange={handleInputChange}
                />
              </CardContent>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%" }} aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell sx={{ padding: "28px 0px" }}/>
                        <TableCell />
                        <TableCell>Anúncio</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>Cód. Pedido/Comprador</TableCell>
                        <TableCell>Data/Hora</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Qtde</TableCell>
                        <TableCell>SKU</TableCell>
                        {hasFulfillment === true && (
                          <TableCell sx={{ padding: "30px 0px" }} />
                        )}                        
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading
                        ? Array.from({ length: 3 }).map((_, index) => (
                            <TableRow key={index}>
                              <TableCell colSpan={12}>
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height={50}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        : localReturns.map((row, index) => (
                            <ReturnRow
                              key={index}
                              row={row}
                              index={index}
                              expandedRow={expandedRow}
                              handleExpandClick={handleExpandClick}
                              attachmentsExist={attachmentsExist}
                              handleOpenAttachmentListModal={handleOpenAttachmentListModal}
                              handleOpenDetailDrawer={handleOpenDetailDrawer}
                              handleOpenDescriptionModal={handleOpenDescriptionModal}
                              handleOpenStatusModal={handleOpenStatusModal}
                              handleOpenInputFileModal={handleOpenInputFileModal}
                              handleOpenReviewModal={handleOpenReviewModal}
                              handleShopeeAwaitReturnModal={handleShopeeAwaitReturnModal}
                              handleShopeePartialRefundModal={handleShopeePartialRefundModal}
                              handleShopeeTotalRefundModal={handleShopeeTotalRefundModal}
                              hasFulfillment={hasFulfillment}
                              onOpenItemsDrawer={handleOpenItemsDrawer}
                            />
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalCount}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChanged}
                  page={paginate?.page}
                  rowsPerPage={paginate?.size}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  labelRowsPerPage="Devoluções por página"
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${count == 0 ? 0 : from }-${to} de ${count}`;
                  }}
                />
              </Scrollbar>
            </Grid>
          </Grid>
        </Card>
      )}

      <StatusModal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        selectedStatus={selectedStatus}
        handleStatusChange={handleStatusChange}
        handleUpdateStatus={handleUpdateStatus}
        loading={loadingComponents}
      />
      <DescriptionModal
        open={openDescriptionModal}
        onClose={handleCloseDescriptionModal}
        selectedDescription={selectedDescription}
        handleDescriptionChange={handleDescriptionChange}
        handleUpdateDescription={handleUpdateDescription}
        loading={loadingComponents}
      />
      <UploadFileModal
        open={openInputFileModal}
        onClose={handleCloseInputFileModal}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
      />
      <ReturnAttachmentList
        open={openAttachmentListModal}
        onClose={handleCloseAttachmentListModal}
        attachments={attachments}
        onRemove={handleRemoveAttachment}
        selectedReturnId={selectedReturnId}
        returnItem={selectedReturnItem}
      />
      <ReviewModal
        open={openReviewModal}
        onClose={handleCloseReviewModal}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
        updateReturnData={setUpdatedOnReview}
        platform_name={selectedReturnItem?.platform_name}
      />
      <DrawerDetail
        open={openDetailDrawer}
        onClose={handleCloseDetailDrawer}
        details={selectedDetailItem?.details || []}
        shippingHistory={selectedDetailItem?.shipping_history || []}
        reviewStatus={selectedDetailItem}
      />
      <PartialRefound
        open={openShopeePartialRefundModal}
        close={handleCloseShopeePartialRefundModal}
        setRefundData={setRefundData}
        activeChat={selectedReturnItem}
      />
      <MultiplesItemsComponent
        open={openItemsModal}
        onClose={handleCloseItemsDrawer}
        items={modalItems}
      />
      <AwaitReturnModal
        open={openShopeeAwaitReturnModal}
        close={handleCloseShopeeAwaitReturnModal}
        returnItem={selectedReturnItem}
      />
      <Refound
         showRefound={openShopeeTotalRefundModal}
         setShowRefound={setOpenShopeeTotalRefundModal}
         activeChat={selectedReturnItem}
         setRefundData={setRefundData}
      />
    </Box>
  );
};

export default ReturnTable;
