import React from 'react';
import styled from 'styled-components';
import { Typography, Box as MuiBox } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const DetailContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 0.5rem;
  padding: 16px;
  margin: 0.625rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Detail = ({ details }) => {
  const theme = useTheme();
  const bgColor = theme.palette.mode === "dark" ? "#1B263B" : "#f9f9f9";

  return (
    <DetailContainer bgColor={bgColor}>
      <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
        {details?.title}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
        {details?.description}
      </Typography>
    </DetailContainer>
  );
};
