import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";

const ReviewStatusContainer = styled.div`
  background-color: ${(props) => props.bgColor};
  border-radius: 0.5rem;
  padding: 16px;
  widht: 100%;
  margin: 0.625rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const statusLabels = {
  pending: "Pendente",
  handling: "Preparação",
  ready_to_ship: "Etiqueta pronta para despachar",
  shipped: "Enviado",
  not_delivered: "Não entregue",
  delivered: "Entregue",
  cancelled: "Envio cancelado",
};

const substatusLabels = {
  ready_to_print: "Pronto para imprimir",
  printed: "Impresso",
  dropped_off: "Liberado",
  picked_up: "Recolhido",
  in_hub: "No hub",
  in_packing_list: "Embalado",
  first_visit: "Primeira visita",
  second_visit: "Segunda visita",
  third_visit: "Terceira visita",
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};


const groupShippingHistory = (shippingHistory) => {
  const groupedHistory = [];

  shippingHistory.forEach((item) => {
    const lastGroup = groupedHistory[groupedHistory.length - 1];

    if (lastGroup && lastGroup.status === item.status) {
      if (item.substatus) {
        lastGroup.subSteps.push({
          substatus: item.substatus,
          date: item.date,
        });
      }
    } else {
      groupedHistory.push({
        status: item.status,
        date: item.date,
        subSteps: item.substatus
          ? [{ substatus: item.substatus, date: item.date }]
          : [],
      });
    }
  });

  return groupedHistory;
};

const TimeLine = ({ shippingHistory }) => {
  const theme = useTheme();
  const validShippingHistory = Array.isArray(shippingHistory) ? shippingHistory : [];
  const bgColor = theme.palette.mode === "dark" ? "#1B263B" : "#f9f9f9";

  if (!validShippingHistory || validShippingHistory.length === 0) {
    return null;
  }

  const timelineRef = useRef(null);

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  }, [validShippingHistory]);

  const groupedHistory = groupShippingHistory(validShippingHistory);
  const activeStep = groupedHistory.length - 1;

  return (
    <ReviewStatusContainer bgColor={bgColor}>
      <Typography variant="h6" color="primary" sx={{ marginBottom: 2 }}>
        Histórico da entrega
      </Typography>
      <Box
        ref={timelineRef}
        sx={{
          width: "100%",
          maxHeight: "50vh",
          height: "45vh",
          overflowY: "auto",
          paddingLeft: "inherit",
        }}
      >
        <Stepper orientation="vertical">
          {groupedHistory.map((step, index) => (
            <Step key={index} expanded>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: index === activeStep ? "primary.main" : "grey.500",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color:
                      index === activeStep ? "primary.main" : "text.secondary",
                  }}
                >
                  {statusLabels[step.status] || step.status}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    ml: 1,
                    color:
                      index === activeStep ? "primary.main" : "text.secondary",
                  }}
                >
                  {formatDate(step.date)}
                </Typography>
              </StepLabel>
              {step.subSteps.length > 0 && (
                <StepContent>
                  <Box sx={{ pl: 4 }}>
                    {step.subSteps.map((subStep, subIndex) => (
                      <Box key={subIndex} sx={{ mb: 1 }}>
                        <Typography variant="body2">
                          <i>
                            {formatDate(subStep.date)} -{" "}
                            {substatusLabels[subStep.substatus] ||
                              subStep.substatus}
                          </i>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </StepContent>
              )}
            </Step>
          ))}
        </Stepper>
      </Box>
    </ReviewStatusContainer>
  );
};

export default TimeLine;