import {
  CardContent,
  Typography,
  Card,
  CardActionArea,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const CounterCard = ({
  title,
  value,
  borderColor,
  onClick,
  isSelected,
  icon,
  appliesTransform = true
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: 5,
        opacity: isSelected ? 1 : 0.5,
        borderLeft: `5px solid ${borderColor}`,
        borderRight: isSelected ? `5px solid ${borderColor}` : "none",
        backgroundColor: isSelected ? `${borderColor}20` : theme.palette.mode === "dark" ? "#1b263b" : "white",
        height: "100px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        transition:
          "background-color 0.3s ease, height 0.3s ease, transform 0.3s ease",
        transform: isSelected ? "translateY(-20px)" : "translateY(0)",
        "&:hover": {
          backgroundColor: isSelected ? `${borderColor}20` : `${borderColor}40`,
          transform:  isSelected ? "translateY(-20px)" : appliesTransform ? "translateY(-10px)" : "translateY(0px)",
          opacity: 1,
        },
      }}
    >
      <CardActionArea onClick={onClick} sx={{ height: "100%", width: "100%" }}>
        <CardContent
          sx={{ width: "100%", height: "100%", padding: "19px 24px" }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{ height: "100%" }}
          >
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                {icon}
                <Typography
                  sx={{
                    whiteSpace: "break-spaces",
                    wordWrap: "break-word",
                    }}
                >
                  {title}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                justifyContent: "flex-end",
                }}
            >
              <Typography fontSize="24px" color={borderColor}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CounterCard;
