import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useState, useEffect } from "react";
import { useAccounts } from "src/contexts/account-context";
import { useFilters } from "src/contexts/filters-context";
import { getChatType } from "src/utils/get-chat-type";

export const AccountsOrdering = () => {
  const conversationType = getChatType();

  const marketplaceByConversation = {
    question: ["MLB", "MZL"],
    message: ["MLB"],
    claim: ["MLB"],
    chat: ["SHO"],
    return: ["MLB", "SHO"],
  };

  const { accounts, fetchAccounts } = useAccounts(); 
  const filteredAccounts = accounts.filter(
    (account) =>
      marketplaceByConversation[conversationType].includes(account.platform.acronym) &&
      !!account.userId
  );

  useEffect(() => {
    const getAccounts = async () => {
      if (!accounts || accounts?.length === 0) {
        await fetchAccounts();
      }
    };
    getAccounts();
  }, []);

  const options = [
    {
      value: "all",
      label: "Contas (todas)",
    },
    ...filteredAccounts.map((account) => ({
      value: account.id,
      label: account.accountName,
    })),
  ];
  const { filters, setFilters } = useFilters();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index, account) => {
    setFilters((prevState) => ({ ...prevState, account, accountIndex: index }));
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          sx={{
            height: "30px",
            cursor: "pointer",
            maxWidth: "150px",
            paddingLeft: 2,
            paddingRight: 1,
          }}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle2" sx={{ fontWeight: 400 }} noWrap>
                {options[filters.accountIndex]?.label}
              </Typography>
            }
          />
          <UnfoldMoreIcon
            sx={{
              cursor: "pointer",
            }}
            onClick={handleClickListItem}
          />
        </ListItem>
      </List>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{ width: "100%" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === filters.accountIndex}
            onClick={() => handleMenuItemClick(index, option.value)}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
