import React, { useEffect, useState } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { api } from "src/services/api";
import toast from "react-hot-toast";
import StageTwo from "../stage-two";
import StageThree from "../stage-three";
import { getDisputeReason } from "src/utils/utils-returns";
import { s3 } from "src/utils/storage-provider";
import { PutObjectCommand, ListObjectsV2Command } from "@aws-sdk/client-s3";


const ShopeeDisputeModal = ({
  open,
  onClose,
  selectedReturnId,
  updateAttachmentStatus,
  updateReturnData,
  user,
  platform_name
}) => {
  const [loading, setLoading] = useState(false);
  const [failReasons, setFailReasons] = useState([]);
  const [stage, setStage] = useState(2);
  const [selectedReason, setSelectedReason] = useState(null);
  const [s3Files, setS3Files] = useState([]);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");

  const fetchReturnFailReason = async () => {
    try {
      setLoading(true);
      
      const response = await api.get(`/v1/chats/dispute-reason/${selectedReturnId}`);
      
      if (Array.isArray(response.data) && response.data.length > 0) {        
        const translatedReasons = getDisputeReason(response.data);
  
        const failReasons = response.data.map((code, index) => ({
          id: code, 
          detail: translatedReasons[index]
        }));
  
        setFailReasons(failReasons);
      } else {
        console.log("Nenhuma razão de disputa encontrada ou formato inválido.");
        setFailReasons([]);
      }
  
    } catch (error) {
      console.error("Erro ao buscar razões ", error);
    } finally {
      setLoading(false);
    }
  };  

  const handleOpenDispute = async () => {
    try {
      setLoading(true);
      await api.post(`/v1/chats/open-dispute/${selectedReturnId}`, {
        reason: selectedReason.id,
        message: description,
        email,
        s3Keys: s3Files.map((file) => file.key),
      });
      toast.success("Revisão enviada com sucesso");
      handleClose();
    } catch (error) {
      toast.error("Erro ao enviar revisão");
    } finally {
      updateReturnData(true);
      setLoading(false);
    }
  };

  const fetchS3Files = async () => {
    const attachmentsPath = `tenants/${user.customer}/returnAttachments/${selectedReturnId}/`;
    
    try {
      setLoading(true);
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Prefix: attachmentsPath,
      };
      const data = await s3.send(new ListObjectsV2Command(params));
      const s3Attachments = data.Contents
        ? data.Contents.map((item) => ({
            name: item.Key.split("/").pop(),
            url: `https://assets-s3.weesutech.com.br/${item.Key}`,
            key: item.Key,
            type: item.Key.split(".").pop(), // Get file type
          })).filter((file) => ["jpg", "jpeg", "png", "pdf"].includes(file.type))
        : [];

      setS3Files(s3Attachments);
    } catch (error) {
      console.error("Erro ao carregar anexos do S3:", error);
      toast.error("Erro ao carregar anexos", {
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveS3FileFromList = (fileKey) => {
    setS3Files((prevFiles) => prevFiles.filter((file) => file.key !== fileKey));
  };

  const handleClose = () => {
    setFailReasons([]);
    setStage(2);
    setSelectedReason(null);
    setS3Files([]);
    setDescription("");
    onClose(false);
  };

  const handleReasonSelect = (reason) => {
    setSelectedReason(reason);
    setStage(3);
  };

  const handleBack = () => {
    if (stage === 3) {
      setSelectedReason(null);
      setStage(2);
    }
  }; 

  useEffect(() => {
    if (open && stage === 2) {
      fetchReturnFailReason();
    }
    if (open && stage === 3) {
      fetchS3Files();
    }
  }, [open, stage]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 4,
          bgcolor: "background.paper",
          borderRadius: "16px",
          maxWidth: 700,
          width: "90%",
        }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>

        {stage === 2 && (
          <StageTwo
            loading={loading}
            failReasons={failReasons}
            handleReasonSelect={handleReasonSelect}
            handleBack={handleBack}
          />
        )}
        {stage === 3 && (
          <StageThree
            loading={loading}
            description={description}
            setDescription={setDescription}
            s3Files={s3Files}
            setS3Files={setS3Files}
            handleRemoveS3FileFromList={handleRemoveS3FileFromList}
            handleBack={handleBack}
            handleSendReviewFail={handleOpenDispute}
            selectedReason={selectedReason}
            user={user}
            selectedReturnId={selectedReturnId}
            setLoading={setLoading}
            updateAttachmentStatus={updateAttachmentStatus}
            platform_name={platform_name}
            email={email}
            setEmail={setEmail}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ShopeeDisputeModal;
