const { REACT_APP_APP_ID } = process.env;
export const httpConfig = {
  apiUrl: {
    development: "http://localhost:3005",
    homolog: "https://api-weello-homolog.crover.hml.weesu.com.br",
    production: "https://api-weello.crover.weello.weesutech.com.br",
    sprint: "https://api-weello-sprint.crover.hml.weesu.com.br",
  },
};

export const msConfig = {
  apiUrl: {
    development: "https://auth.weesu.com.br",
    homolog: "https://api-auth-homolog.crover.hml.weesu.com.br",
    production: "https://auth.weesu.com.br",
    sprint: "https://api-auth-sprint.crover.hml.weesu.com.br",
  },
};

export const meliIdConfig = {
  apiId: {
    development: "7419085301179814",
    homolog: "7419085301179814",
    production: "7772983435845719",
    sprint: "5030175853599966",
  },
};

export const appIdConfig = {
  development: REACT_APP_APP_ID || "5",
  homolog: "4",
  production: "3",
  sprint: "5",
};
