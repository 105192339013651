import { Stack, Typography, Divider, Skeleton, useMediaQuery } from "@mui/material";
import { Row, ProductImage, ProductName, Container, ProductCard, TitleWrapperLimitLines } from "./style";
import styled from "styled-components";
import { DetailContainer, DetailInfoTitle, DetailInfoValue } from '../AnnouncementItem/AnnouncementItemFurtherInfo.styles';

export const OrderItem = ({ loading, value, isLast }) => {
  const smallSize = useMediaQuery('(max-width:414px)');

  const unitPrice = parseFloat(value?.unit_price ? value?.unit_price : value?.item_price).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  
  const attributes = value?.item?.variation_attributes;
  return (
    <>
      {loading ? (
        <>
          <Row alignItems="start">
            <Row alignItems="start">
              <Skeleton variant="rounded" width="4rem" height="4rem" />
              <Stack>
                <Typography variant="subtitle1">
                  <Skeleton width={100} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton width={70} />
                </Typography>
                <Typography variant="subtitle1">
                  <Skeleton width={70} />
                </Typography>
              </Stack>
            </Row>

            <Stack alignItems="flex-end" py={1} spacing={0.5} pr="0.5rem">
              <Skeleton width={70} />
              <Skeleton width={30} />
            </Stack>
          </Row>
          {!isLast && (
            <Divider
              sx={{
                marginTop: 1,
                marginBottom: 1,
                marginRight: 1,
              }}
            />
          )}
        </>
      ) : (
        <>
          <Container>
            <ProductCard>
              <Row alignItems="start" sx={{ gap: '2.2rem', justifyContent: "space-between", width: '100%' }}>
                <Row alignItems="start" justifyContent="start">
                  <ProductImage size={ smallSize ? "50px" : "90px" } src={value?.item?.thumbnail ? value?.item?.thumbnail : value?.thumbnail} alt="Product Image" />
                  <Stack sx={{ flex: 1 }}>
                  <TitleWrapperLimitLines>
                    {value?.item?.link ? (
                      <ProductName href={value.item.link} target="_blank">
                        {value?.item?.title ? value.item.title : value?.title}
                      </ProductName>
                    ) : (
                      <Typography>{value?.item?.title ? value.item.title : value?.title}</Typography>
                    )}
                  </TitleWrapperLimitLines>
                    <DetailContainer>
                      <DetailInfoTitle>Código:</DetailInfoTitle>
                      <DetailInfoValue>{`${value?.item?.id ? value?.item?.id : value?.item_id}`}</DetailInfoValue>
                    </DetailContainer>
                    <DetailContainer>
                      <DetailInfoTitle>Categoria:</DetailInfoTitle>
                      <DetailInfoValue>{`${value?.item?.category_id ? value?.item?.category_id : value?.variation_sku}`}</DetailInfoValue>
                    </DetailContainer>
                    {attributes?.map((attribute) => (
                        <DetailContainer>
                          <DetailInfoTitle>{`${attribute?.name}:`}</DetailInfoTitle>
                          <DetailInfoValue>{`${attribute?.value_name}`}</DetailInfoValue>
                        </DetailContainer>
                    ))}
                  </Stack>
                </Row>
                <Stack alignItems="flex-end" py={1} spacing={0.5} >
                  <Stack
                    sx={{
                      backgroundColor: "primary.main",
                      padding: "1px 5px 1px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="subtitle1" color="primary.contrastText">
                      {unitPrice}
                    </Typography>
                  </Stack>
                  <Typography variant="subtitle1">{`x${value?.quantity}`}</Typography>
                </Stack>
              </Row>
            </ProductCard>
          </Container>
          {!isLast && (
            <Divider
              sx={{
                marginTop: 1,
                marginBottom: 1,
                marginRight: 1,
              }}
            />
          )}
        </>
      )}
    </>
  );
};
