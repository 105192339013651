import React from "react";
import MercadoLivreReviewModal from "./components/mercadolivre-review";
import ShopeeDisputeModal from "./components/shopee/shopee-dispute";
import { useUser } from "src/contexts/user-context";


const ReviewModal = ({
  open,
  onClose,
  selectedReturnId,
  platform_name,
  updateAttachmentStatus,
  updateReturnData,  
}) => {
  const { user } = useUser();
  if (platform_name === "MercadoLivre") {
    return (
      <MercadoLivreReviewModal
        open={open}
        onClose={onClose}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
        updateReturnData={updateReturnData}
        user={user}
      />
    );
  } else if (platform_name === "Shopee") {
    return (
      <ShopeeDisputeModal
        open={open}
        onClose={onClose}
        selectedReturnId={selectedReturnId}
        updateAttachmentStatus={updateAttachmentStatus}
        updateReturnData={updateReturnData}
        user={user}
        platform_name={platform_name}
      />
    );
  }
  
  return null;
};

export default ReviewModal;
