import { createContext, useContext, useState, useEffect } from "react";

// Chave para o localStorage
const LOCAL_STORAGE_KEY = 'notificationsMessages';

const NotificationMessageContext = createContext();

export const NotificationMessageProvider = ({ children }) => {
  // Inicializa o estado com os dados do localStorage, se existirem, ou com um array vazio
  const [notificationsMessages, setNotificationsMessages] = useState(() => {
    const storedMessages = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedMessages && storedMessages !== 'undefined' ? JSON.parse(storedMessages) : [];
  });

  // Efeito para atualizar o localStorage sempre que as notificações mudarem
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(notificationsMessages));
  }, [notificationsMessages]);

  return (
    <NotificationMessageContext.Provider value={{ notificationsMessages, setNotificationsMessages }}>
      {children}
    </NotificationMessageContext.Provider>
  );
};

export const useNotificationsMessages = () => {
  return useContext(NotificationMessageContext);
};
